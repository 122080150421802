var forgotpass = {
		inputs: null,
		submitButton: null,
		validationErrors: null,
		init: function(){
			forgotpass.validationErrors	= window.validationErrors;
			forgotpass.inputs			= $('INPUT[type="text"], INPUT[type="password"]');
			forgotpass.submitButton		= $('BUTTON[type="submit"]');
			
			forgotpass.validationErrors.init('#forgot-form');
			
			$('#forgot-form').on('submit', forgotpass.submit);
			
		},
		submit: function(e){
			e.preventDefault();

			// Strip tags
			if($('#email').length) {
				$('#email').val($('#email').val().replace(/(<([^>]+)>)/ig, ""));
			}
			
			var valid = true;
			var redirect = $(this).data('redirecturl');
			//console.log(redirect);
			forgotpass.validationErrors.clear();
			if(forgotpass.validationErrors.check() === false) {
				valid = false;
				return false;
			}
			if(valid){
				forgotpass.submitButton.addClass('disabled');
				$.ajax({
					method: "POST",
					url: $( this ).attr('action'),
					data: $( this ).serialize()
				})
				.done(function( res ) {
					if(res.success === true){
						$('.forgotform').fadeOut('slow', function(){
							$('.forgotform-confirm').fadeIn('slow');
						});
						
						//window.location.href = redirect;
					}else{
						forgotpass.submitButton.removeClass('disabled');
						forgotpass.validationErrors.show(res.errors);
					}
				});
			}
		}
};

var resetpass = {
		inputs: null,
		submitButton: null,
		validationErrors: null,
		init: function(){
			resetpass.validationErrors	= window.validationErrors;
			resetpass.inputs			= $('INPUT[type="text"], INPUT[type="password"]');
			resetpass.submitButton		= $('BUTTON[type="submit"]');
			
			resetpass.validationErrors.init('#reset-form');
			
			$('#reset-form').on('submit', resetpass.submit);
			
		},
		submit: function(e){
			e.preventDefault();
			
			var valid = true;
			var redirect = $(this).data('redirecturl');
			
			resetpass.validationErrors.clear();
			if(resetpass.validationErrors.check() === false) {
				valid = false;
				return false;
			}
			if(valid){
				resetpass.submitButton.addClass('disabled');
				$.ajax({
					method: "POST",
					url: $( this ).attr('action'),
					data: $( this ).serialize()
				})
				.done(function( res ) {
					if(res.success === true){
						//window.location.href = redirect;
						$('#reset-form').fadeOut('slow', function(){$('#reset-success-message').fadeIn('slow');});
						
					}else{
						resetpass.submitButton.removeClass('disabled');
						resetpass.validationErrors.show(res.errors);
					}
				});
			}
		}
};


$(document).ready(function() {
	if($('FORM#forgot-form').length > 0)
		forgotpass.init();
	if($('FORM#reset-form').length > 0)
		resetpass.init();
});