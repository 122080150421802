$(function() {
	if($('.printing-document').length > 0){
		$('BODY').addClass('printing-document');
		window.print();
	}
	
	$('.back-to-top').on('click', function(){scroll.to($('html'));});
});

$(function() {
    var num_cols = 3,
    container = $('.split-list'),
    listItem = 'li',
    listClass = 'sub-list';
    container.each(function() {
        var items_per_col = new Array(),
        items = $(this).find(listItem),
        min_items_per_col = Math.floor(items.length / num_cols),
        difference = items.length - (min_items_per_col * num_cols);
        for (var i = 0; i < num_cols; i++) {
            if (i < difference) {
                items_per_col[i] = min_items_per_col + 1;
            } else {
                items_per_col[i] = min_items_per_col;
            }
        }
        for (var i = 0; i < num_cols; i++) {
            $(this).append($('<ul ></ul>').addClass(listClass));
            for (var j = 0; j < items_per_col[i]; j++) {
                var pointer = 0;
                for (var k = 0; k < i; k++) {
                    pointer += items_per_col[k];
                }
                $(this).find('.' + listClass).last().append(items[j + pointer]);
            }
        }
    });
});


var validationErrors = {
	form				: null,
	inputs				: null,
	wrapperClass		: '.input-group',
	defaultErrorWrapper	: '.default-errors',
	errorEles			: [],
	errorsTimeout		: 4000,
	
	init: function(form){
		if(form.length == 0) return false;
		validationErrors.form	= $(form);
		validationErrors.inputs	= $(validationErrors.form).find('INPUT[required], TEXTAREA[required], SELECT[required]');
		
		$(document).on('keyup, blur, focus', '.error INPUT[required], .error TEXTAREA[required], .error SELECT[required]', function(){ 
			$(this).parents(validationErrors.wrapperClass+':first').removeClass('error');
		});

	},
	show: function(errorsObj){
		if(validationErrors.form == null) return false;
		if(typeof errorsObj == 'undefined') return false;
		var found = false;
		for (var k in errorsObj){
			if (errorsObj.hasOwnProperty(k)) {
				if(k === 'default'){
					var ele = $(validationErrors.defaultErrorWrapper);
					if(ele.length > 0){
						ele.html(errorsObj[k]).show();
						//console.log(errorsObj[k]);
					}
				}else{
			    	var ele = $("INPUT[name="+k+"], TEXTAREA[name="+k+"], SELECT[name="+k+"]");
			    	var eEle = ele.parents(validationErrors.wrapperClass+':first');
			    	eEle.find('.error-message').css({'opacity': 1});
			    	eEle.addClass('error');
			    	//console.log(eEle);
					//$("INPUT[name="+k+"], TEXTAREA[name="+k+"], SELECT[name="+k+"]").parents(validationErrors.wrapperClass+':first').addClass('error');
					found = true;
					
					
					setTimeout(function(){ eEle.find('.error-message').css({'opacity': 0}); }, validationErrors.errorsTimeout - 350);
					setTimeout(function(){ eEle.removeClass('error'); }, validationErrors.errorsTimeout);
				}
			}
		}
		return found;
	},
	hide: function(errorsObj){
		if(validationErrors.form == null) return false;
		
		$(validationErrors.defaultErrorWrapper).html('').hide(); // default error wrapper
		
		for (var k in errorsObj){
		    if (errorsObj.hasOwnProperty(k)) {
		    	var ele = $("INPUT[name="+k+"], TEXTAREA[name="+k+"], SELECT[name="+k+"]");
		    	var eEle = ele.parents(validationErrors.wrapperClass+':first');
		    	eEle.find('.error-message').css({'opacity': 0});
		    	eEle.removeClass('error');
		    	
		    }
		}
	},
	clear: function(){
		if(validationErrors.form == null) return false;
		validationErrors.form.find(validationErrors.wrapperClass+'.error').removeClass('error');
		$(validationErrors.defaultErrorWrapper).html('').hide(); // default error wrapper
	},
	check: function(){
		if(validationErrors.form == null) return false;
		$(validationErrors.defaultErrorWrapper).html('').hide(); // default error wrapper
		validationErrors.inputs	= $(validationErrors.form).find('INPUT[required], TEXTAREA[required], SELECT[required]');
		
		var radio_checked	= [];
		validationErrors.inputs.each(function(){
			var ele = $(this);
			var eEle = ele.parents(validationErrors.wrapperClass+':first');

			if(ele.attr('type') == 'checkbox') {
				if(!ele.is(':checked')){
					eEle.addClass('error');
					eEle.find('.error-message').css({'opacity': 1});
				}
			}else if(ele.attr('type') == 'radio'){
				if(!ele.is(':checked') && radio_checked.indexOf(ele.attr('name')) === -1){
					eEle.addClass('error');		
					eEle.find('.error-message').css({'opacity': 1});			
				}else{
					eEle.removeClass('error');
					radio_checked.push(ele.attr('name'));
				}
			}else{
				if(ele.val().length == 0){
					eEle.addClass('error');
					eEle.find('.error-message').css({'opacity': 1});
				}
			}
			setTimeout(function(){ eEle.find('.error-message').css({'opacity': 0}); }, validationErrors.errorsTimeout -350);
			setTimeout(function(){ eEle.removeClass('error'); }, validationErrors.errorsTimeout);
		});
		var valid = validationErrors.form.find(validationErrors.wrapperClass+'.error').length == 0;
		return valid;
	}
};

var scroll = {
		to: function(ele){
			$('html, body').animate({
			    scrollTop: ele.offset().top - 100
			}, 300);
		}
};
var parseQueryString = function( queryString ) {
	if(queryString == undefined || queryString.length == 0) return;
	var ind = queryString.indexOf("?");
	if(ind !== false)
		queryString = queryString.substring(ind+1);
    var params = {}, queries, temp, i, l;
    queries = queryString.split("&");
    for ( i = 0, l = queries.length; i < l; i++ ) {
        temp = queries[i].split('=');
        params[temp[0]] = temp[1];
    }
    return params;
};

var inputAllowedLength = {
		init: function(){
			$('INPUT[data-allowedlength]').on('keyup', function(e){
				var value			= $(this).val();
				var length			= value.length;
				var allowedLength	= $(this).attr('data-allowedlength');
				var remainingChar	= allowedLength - length;
				var charDiv			= $(this).parent().find('.remaining-char');
				if(charDiv.length === 1){
					var remaining_char = remainingChar <= 0 ? 0 : remainingChar;
					charDiv.html(remaining_char);
				}
				$(this).val(value.substring(0, allowedLength));
				return true;
			});
		},
		resetCharCount: function(){
			$('INPUT[data-allowedlength]').each(function(){
				var allowedLength	= $(this).attr('data-allowedlength');
				var charDiv			= $(this).parent().find('.remaining-char');
				if(charDiv.length === 1){
					charDiv.html(allowedLength);
				}
			});
		}
};

/*
function getURLParameterByName(name) {
    name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
        results = regex.exec(location.search);
    return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
}
*/