$(document).ready(function() {
	$( "#purchase-date1" ).datepicker(	{ firstDay: 1,	changeMonth: true,		changeYear: true,	dateFormat: "dd/mm/yy", maxDate: new Date() } );
	$( "#purchase-date2" ).datepicker(	{ firstDay: 1,	changeMonth: true,		changeYear: true,	dateFormat: "dd/mm/yy", maxDate: new Date() } );
	$( "#pickup-date" ).datepicker( 	{ firstDay: 1,	dateFormat: "dd/mm/yy",	minDate: 1, 		beforeShowDay: $.datepicker.noWeekends } );	
	
	var loc	= App.locale;
	$.datepicker.regional[loc] = dateLocalisation(loc);
	$.datepicker.setDefaults($.datepicker.regional[loc]);
});

$(function() {
    $( "#search-date1" ).datepicker({
      dateFormat: "dd/mm/yy",
      defaultDate: "-1w",
      changeMonth: true,
      changeYear: true,
      numberOfMonths: 1,
      maxDate: new Date(),
      onClose: function( selectedDate ) {
        $( "#search-date2" ).datepicker( "option", "minDate", selectedDate );
      }
    });
    $( "#search-date2" ).datepicker({
      dateFormat: "dd/mm/yy",
      defaultDate: new Date(),
      changeMonth: true,
      changeYear: true,
      numberOfMonths: 1,
      maxDate: new Date(),
      onClose: function( selectedDate ) {
    	  if(!selectedDate )
    		  selectedDate = new Date();
    	  $( "#search-date1" ).datepicker( "option", "maxDate", selectedDate );
      }
    });
});


var dateLocalisation = function(locale){
					if(typeof locale == 'undefined'){
						locale = 'en';
					}
							
					var trans	= {
								en: {
								    closeText: 'Close',
								    prevText: 'Previous',
								    nextText: 'Next',
								    currentText: 'Current',
								    monthNames: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
								    monthNamesShort: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
								    dayNames: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
								    dayNamesShort: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
								    dayNamesMin: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
								    weekHeader: 'Week',
								    dateFormat: 'dd/mm/yy',
								    firstDay: 1,
								    isRTL: false,
								    showMonthAfterYear: false,
								    yearSuffix: ''
								  },
								da: {
								    closeText: 'Close',
								    prevText: 'Previous',
								    nextText: 'Next',
								    currentText: 'Current',
								    monthNames: ["januar", "februar", "marts", "april", "maj", "juni", "juli", "august", "september", "oktober", "november", "december"],
								    monthNamesShort: ["jan", "feb", "mar", "apr", "maj", "jun", "jul", "aug", "sep", "okt", "nov", "dec"],
								    dayNames: ["sø", "ma", "ti", "on", "to", "fr", "lø"],
								    dayNamesShort: ["sø", "ma", "ti", "on", "to", "fr", "lø"],
								    dayNamesMin: ["sø", "ma", "ti", "on", "to", "fr", "lø"],
								    weekHeader: 'Week',
								    dateFormat: 'dd/mm/yy',
								    firstDay: 1,
								    isRTL: false,
								    showMonthAfterYear: false,
								    yearSuffix: ''
								  },
								de: {
								    closeText: 'Close',
								    prevText: 'Previous',
								    nextText: 'Next',
								    currentText: 'Current',
								    monthNames: ["Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"],
								    monthNamesShort: ["Jan.", "Febr.", "März", "Apr.", "Mai", "Juni", "Juli", "Aug.", "Sept.", "Okt.", "Nov.", "Dez."],
								    dayNames: ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"],
								    dayNamesShort: ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"],
								    dayNamesMin: ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"],
								    weekHeader: 'Week',
								    dateFormat: 'dd/mm/yy',
								    firstDay: 1,
								    isRTL: false,
								    showMonthAfterYear: false,
								    yearSuffix: ''
								  },
								es: {
								    closeText: 'Close',
								    prevText: 'Previous',
								    nextText: 'Next',
								    currentText: 'Current',
								    monthNames: ["enero", "febrero", "marzo", "abril", "mayo", "junio", "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"],
								    monthNamesShort: ["ene", "feb", "mar", "abr", "may", "jun", "jul", "ago", "sep", "oct", "nov", "dic"],
								    dayNames: ["do", "lu", "ma", "mi", "ju", "vi", "sa"],
								    dayNamesShort: ["do", "lu", "ma", "mi", "ju", "vi", "sa"],
								    dayNamesMin: ["do", "lu", "ma", "mi", "ju", "vi", "sa"],
								    weekHeader: 'Week',
								    dateFormat: 'dd/mm/yy',
								    firstDay: 1,
								    isRTL: false,
								    showMonthAfterYear: false,
								    yearSuffix: ''
								  },
								fi: {
								    closeText: 'Close',
								    prevText: 'Previous',
								    nextText: 'Next',
								    currentText: 'Current',
								    monthNames: ["tammikuu", "helmikuu", "maaliskuu", "huhtikuu", "toukokuu", "kesäkuu", "heinäkuu", "elokuu", "syyskuu", "lokakuu", "marraskuu", "joulukuu"],
								    monthNamesShort: ["Tammi", "Helmi", "Maalis", "Huhti", "Touko", "Kesä", "Heinä", "Elo", "Syys", "Loka", "Marras", "Joulu"],
								    dayNames: ["Su", "Ma", "Ti", "Ke", "To", "Pe", "La"],
								    dayNamesShort: ["Su", "Ma", "Ti", "Ke", "To", "Pe", "La"],
								    dayNamesMin: ["Su", "Ma", "Ti", "Ke", "To", "Pe", "La"],
								    weekHeader: 'Week',
								    dateFormat: 'dd/mm/yy',
								    firstDay: 1,
								    isRTL: false,
								    showMonthAfterYear: false,
								    yearSuffix: ''
								  },
								fr: {
								    closeText: 'Close',
								    prevText: 'Previous',
								    nextText: 'Next',
								    currentText: 'Current',
								    monthNames: ["janvier", "février", "mars", "avril", "mai", "juin", "juillet", "août", "septembre", "octobre", "novembre", "décembre"],
								    monthNamesShort: ["janv.", "févr.", "mars", "avr.", "mai", "juin", "juil.", "août", "sept.", "oct.", "nov.", "déc."],
								    dayNames: ["Di", "Lu", "Ma", "Me", "Je", "Ve", "Sa"],
								    dayNamesShort: ["Di", "Lu", "Ma", "Me", "Je", "Ve", "Sa"],
								    dayNamesMin: ["Di", "Lu", "Ma", "Me", "Je", "Ve", "Sa"],
								    weekHeader: 'Week',
								    dateFormat: 'dd/mm/yy',
								    firstDay: 1,
								    isRTL: false,
								    showMonthAfterYear: false,
								    yearSuffix: ''
								  },
								it: {
								    closeText: 'Close',
								    prevText: 'Previous',
								    nextText: 'Next',
								    currentText: 'Current',
								    monthNames: ["gennaio", "febbraio", "marzo", "aprile", "maggio", "giugno", "luglio", "agosto", "settembre", "ottobre", "novembre", "dicembre"],
								    monthNamesShort: ["gen", "feb", "mar", "apr", "mag", "giu", "lug", "ago", "set", "ott", "nov", "dic"],
								    dayNames: ["do", "lu", "ma", "me", "gi", "ve", "sa"],
								    dayNamesShort: ["do", "lu", "ma", "me", "gi", "ve", "sa"],
								    dayNamesMin: ["do", "lu", "ma", "me", "gi", "ve", "sa"],
								    weekHeader: 'Week',
								    dateFormat: 'dd/mm/yy',
								    firstDay: 1,
								    isRTL: false,
								    showMonthAfterYear: false,
								    yearSuffix: ''
								  },
								nl: {
								    closeText: 'Close',
								    prevText: 'Previous',
								    nextText: 'Next',
								    currentText: 'Current',
								    monthNames: ["januari", "februari", "maart", "april", "mei", "juni", "juli", "augustus", "september", "oktober", "november", "december"],
								    monthNamesShort: ["jan", "feb", "mrt", "apr", "mei", "jun", "jul", "aug", "sep", "okt", "nov", "dec"],
								    dayNames: ["zo", "ma", "di", "wo", "do", "vr", "za"],
								    dayNamesShort: ["zo", "ma", "di", "wo", "do", "vr", "za"],
								    dayNamesMin: ["zo", "ma", "di", "wo", "do", "vr", "za"],
								    weekHeader: 'Week',
								    dateFormat: 'dd/mm/yy',
								    firstDay: 1,
								    isRTL: false,
								    showMonthAfterYear: false,
								    yearSuffix: ''
								  },
								no: {
								    closeText: 'Close',
								    prevText: 'Previous',
								    nextText: 'Next',
								    currentText: 'Current',
								    monthNames: ["januar", "februar", "mars", "april", "mai", "juni", "juli", "august", "september", "oktober", "november", "desember"],
								    monthNamesShort: ["jan", "feb", "mar", "apr", "mai", "jun", "jul", "aug", "sep", "okt", "nov", "des"],
								    dayNames: ["sø", "ma", "ti", "on", "to", "fr", "lø"],
								    dayNamesShort: ["sø", "ma", "ti", "on", "to", "fr", "lø"],
								    dayNamesMin: ["sø", "ma", "ti", "on", "to", "fr", "lø"],
								    weekHeader: 'Week',
								    dateFormat: 'dd/mm/yy',
								    firstDay: 1,
								    isRTL: false,
								    showMonthAfterYear: false,
								    yearSuffix: ''
								  },
								pl: {
								    closeText: 'Close',
								    prevText: 'Previous',
								    nextText: 'Next',
								    currentText: 'Current',
								    monthNames: ["styczeń", "luty ", "marzec", "kwiecień", "maj ", "czerwiec", "lipiec", "sierpień", "wrzesień", "październik", "listopad", "grudzień"],
								    monthNamesShort: ["sty", "lut", "mar", "kwie", "maj", "cze", "lip ", "się", "wrz", "paź", "lis", "gru"],
								    dayNames: ["N", "Pn", "Wt", "Śr", "Cz", "Pt", "So"],
								    dayNamesShort: ["N", "Pn", "Wt", "Śr", "Cz", "Pt", "So"],
								    dayNamesMin: ["N", "Pn", "Wt", "Śr", "Cz", "Pt", "So"],
								    weekHeader: 'Week',
								    dateFormat: 'dd/mm/yy',
								    firstDay: 1,
								    isRTL: false,
								    showMonthAfterYear: false,
								    yearSuffix: ''
								  },
								pt: {
								    closeText: 'Close',
								    prevText: 'Previous',
								    nextText: 'Next',
								    currentText: 'Current',
								    monthNames: ["janeiro", "fevereiro", "março", "abril", "maio", "junho", "julho", "agosto", "setembro", "outubro", "novembro", "dezembro"],
								    monthNamesShort: ["jan", "fev", "mar", "abr", "mai", "jun", "jul", "ago", "set", "out", "nov", "dez"],
								    dayNames: ["D", "S", "T", "Q", "Q", "S", "S"],
								    dayNamesShort: ["D", "S", "T", "Q", "Q", "S", "S"],
								    dayNamesMin: ["D", "S", "T", "Q", "Q", "S", "S"],
								    weekHeader: 'Week',
								    dateFormat: 'dd/mm/yy',
								    firstDay: 1,
								    isRTL: false,
								    showMonthAfterYear: false,
								    yearSuffix: ''
								  },
								sv: {
								    closeText: 'Close',
								    prevText: 'Previous',
								    nextText: 'Next',
								    currentText: 'Current',
								    monthNames: ["januari", "februari", "mars", "april", "maj ", "juni", "juli", "augusti", "september", "oktober", "november", "december"],
								    monthNamesShort: ["jan", "feb", "mar", "apr", "maj ", "jun", "jul", "aug", "sep", "okt", "nov", "dec"],
								    dayNames: ["sö", "må", "ti", "on", "to", "fr", "lö"],
								    dayNamesShort: ["sö", "må", "ti", "on", "to", "fr", "lö"],
								    dayNamesMin: ["sö", "må", "ti", "on", "to", "fr", "lö"],
								    weekHeader: 'Week',
								    dateFormat: 'dd/mm/yy',
								    firstDay: 1,
								    isRTL: false,
								    showMonthAfterYear: false,
								    yearSuffix: ''
								  }						
							};

							return trans[locale];
							
						};
