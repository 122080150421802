var overlay = {
		overlayDiv: null,
		overlayLinks: null,
		overlayWrapper: null,
		overlayContainer: null,
		overlayClose: null,
		
		init: function(){
			overlay.overlayDiv		= $('.overlay');
			overlay.overlayLinks	= $('.open-overlay');
			overlay.overlayClose	= $('.overlay-close-btn');

			overlay.overlayWrapper= $( ".overlay .overlay-wrapper" );
			overlay.overlayContainer= $( ".overlay .overlay-container" );
			
			overlay.loading			= $('.ui-loader-horizontal');

			$(document).on('click', "A.open-overlay", overlay.open);
			$(document).on('click', ".overlay-close-btn", overlay.close);

		},
		open: function(e){
			e.preventDefault();
			var url = $(this).attr('href');
			if(typeof url == 'undefined' || url == '#' || url == '') return;
			
			overlay.overlayDiv.css({'height': $(document).height()});
			overlay.overlayWrapper.css({'top': $(document).scrollTop() + 150});
			
			if($(this).hasClass('add-loading')){
				var newLoading = overlay.loading.clone();
				overlay.overlayContainer.html(newLoading).promise().done(function(){
					newLoading.find('.svg').css({'transform': 'scale(10)'});
					newLoading.addClass('visible');
					newLoading.find('.svg-container').css({'position': 'relative', 'top': '450%', 'left': '45%'});
				});
			}
			
			overlay.overlayContainer.load(url);
			overlay.overlayDiv.addClass('open');
		},
		close: function(e){
			if(typeof e !== 'undefined') e.preventDefault();
			overlay.overlayContainer.html("&nbsp;");
			overlay.overlayDiv.removeClass('open');
		}
};

$(document).ready(function() {
	if($('.open-overlay').length > 0){
		overlay.init();
	}
});